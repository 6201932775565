import type React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import path from 'src/constants/path';
import { useMutation, useQuery } from '@tanstack/react-query';
import authApi from 'src/apis/auth.api';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'src/utils/rules';
import type { SubmitHandler } from 'react-hook-form';
import type { Schema } from 'src/utils/rules';
import type { NoUndefinedField } from 'src/types/utils.type';
import { useContext } from 'react';
import { AppContext } from 'src/contexts/app.context';

type Props = {};
type FormLoginData = NoUndefinedField<Pick<Schema, 'username' | 'password'>>;
const loginSchema = schema.pick(['username', 'password']);
function Copyright(props: { [key: string]: any }) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://intelin.vn/" target="_blank">
        intelin.vn
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login({}: Props) {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AppContext);
  const { t } = useTranslation('login');
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormLoginData>({
    resolver: yupResolver(loginSchema),
  });
  const loginMutation = useMutation({
    mutationFn: (body: FormLoginData) => authApi.login(body),
  });

  const onSubmit: SubmitHandler<FormLoginData> = data => {
    if (data.username === 'admin' && data.password === 'admin') {
      setIsAuthenticated(true);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('SIGN_IN')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <Controller
            name={'username'}
            control={control}
            defaultValue={'admin'}
            render={({ field: { onChange, value } }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={onChange}
                value={value}
                helperText={errors?.username?.message}
                error={Boolean(errors?.username?.message)}
              />
            )}
          />
          <Controller
            name={'password'}
            control={control}
            defaultValue={'admin'}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={Boolean(errors?.password?.message)}
                helperText={errors?.password?.message}
              />
            )}
          />

          <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
