import { Suspense, lazy, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Login from './pages/Login';
import path from './constants/path';
import { AppContext } from './contexts/app.context';

const Loader = Component => props =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const VerticleDashboard = Loader(lazy(() => import('src/pages/Dashboards/VerticleDashboard')));

// Status

const Status404 = Loader(lazy(() => import('src/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/pages/Status/Maintenance')));

function ProtectedRoute() {
  const { isAuthenticated } = useContext(AppContext);
  return isAuthenticated ? <Outlet /> : <Navigate to={path.LOGIN} replace />;
}
function AuthenticationRoute() {
  const { isAuthenticated } = useContext(AppContext);

  return !isAuthenticated ? <Outlet /> : <Navigate to={path.DASHBOARDS} />;
}

const routes: RouteObject[] = [
  {
    path: '',
    element: <AuthenticationRoute />,
    children: [
      {
        path: path.HOME,
        element: (
          <BaseLayout>
            <Login />
          </BaseLayout>
        ),
      },
      {
        path: path.LOGIN,
        element: <Navigate to={path.HOME} replace />,
      },
    ],
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: path.STATUS,
        children: [
          {
            path: '',
            element: <Navigate to={path.PAGE_404} replace />,
          },
          {
            path: path.PAGE_404,
            element: <Status404 />,
          },
          {
            path: path.PAGE_500,
            element: <Status500 />,
          },
          {
            path: path.PAGE_MAINTENANCE,
            element: <StatusMaintenance />,
          },
          {
            path: path.PAGE_COMING_SOON,
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: path.DASHBOARDS,
    element: <ProtectedRoute />,
    children: [
      {
        path: '',
        element: <Navigate to={path.VERTICLE_DASHBOARD} replace />,
      },
      {
        path: path.VERTICLE_DASHBOARD,
        element: (
          <SidebarLayout>
            <VerticleDashboard />
          </SidebarLayout>
        ),
      },
    ],
  },
];

export default routes;
