const path = {
  HOME: '/',
  LOGIN: '/login',
  DASHBOARDS: '/dashboards',
  STATUS: '/status',
  VERTICLE_DASHBOARD: 'verticle',
  PAGE_404: '404',
  PAGE_500: '500',
  PAGE_MAINTENANCE: 'maintaince',
  PAGE_COMING_SOON: 'coming-soon',
} as const;

export default path;
