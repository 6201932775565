import * as yup from 'yup';

export const schema = yup.object({
  username: yup.string().required('Username là bắt buộc'),
  password: yup.string().required('Password là bắt buộc'),
  instanceName: yup.string().required('Instance name là truờng bắt buộc'),
  workerPoolSize: yup.string().required('Worker Pool Size là truờng bắt buộc'),

  queue: yup.array().of(yup.string().required('Queue là truờng bắt buộc')).min(1, 'Queue là truờng bắt buộc'),
});

export type Schema = yup.InferType<typeof schema>;
