import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/sidebar.context';
import * as serviceWorker from 'src/serviceWorker';
import { AppProvider } from './contexts/app.context';
import { I18nextProvider } from 'react-i18next';
import i18n from './locales/index';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

ReactDOM.render(
  <HelmetProvider>
    <AppProvider>
      <SidebarProvider>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </I18nextProvider>
        </BrowserRouter>
      </SidebarProvider>
    </AppProvider>
  </HelmetProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

<App />;
