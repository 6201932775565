import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import localeFeatures from 'src/constants/localeFeatures';

//en
import loginEN from './en/login.en.json';
import verticleDashboardEN from './en/verticle_dashboard.en.json';
import commomEN from './en/common.en.json';

//vn
import loginVN from './vn/login.vn.json';
import verticleDashboardVN from './vn/verticle_dashboard.vn.json';
import commomVN from './vn/common.vn.json';

const resources = {
  en: {
    [localeFeatures.LOGIN]: loginEN,
    [localeFeatures.VERTICLE_DASHBOARD]: verticleDashboardEN,
    [localeFeatures.COMMON]: commomEN,
  },
  vn: {
    [localeFeatures.LOGIN]: loginVN,
    [localeFeatures.VERTICLE_DASHBOARD]: verticleDashboardVN,
    [localeFeatures.COMMON]: commomVN,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
