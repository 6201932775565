import type { AuthResponse } from 'src/types/auth.type';
import { API } from 'src/utils/config';
import { http } from 'src/utils/axios';

const authApi = {
  login(body: { username: string; password: string }) {
    return http.post<AuthResponse>(API.URL_LOGIN, body);
  },
};

export default authApi;
